const playRoar = (() => {
	const init = () => {
		const playSoundButton = document.querySelector('[rel="play-roar"]'),
			roarSound = document.getElementById('roar');
		playSoundButton.addEventListener('click', function() {
			roarSound.play();
		});
	};
	return {
		init,
	};
})();

export default playRoar;
